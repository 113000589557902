import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const aurorasit: IPartnerAppSettings = {
  cdnBaseUrl: '',
  serviceEndPoints: [
    {
      clientId: 'c5779b49-c238-45cb-8974-36958d4c7d96',
      serviceEndPoint: 'https://pslakedsdev.azurewebsites.net/eaapp/api/EA'
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://psdicentraapi-dev.azurewebsites.net/',

    }
  ],
};
