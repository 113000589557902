import { IAppService, IStateConfig, IFxpAppContext, IAppServiceWithSharedComponent,IServiceEndPoints, IRouteInfo,ISharedComponentConfig,ISharedComponents, IPartnerBundle } from '@fxp/fxpservices';
import { HelloWorldComponent } from './app/components/hello-world.component/hello-world.component';
import { HelloWorldModule } from './app/modules/hello-world.module';
import { appSettings } from './environments/common/appsettingsGenerator';
import { ResizableModule } from 'angular-resizable-element';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class PowerBIEmbeddedApplication_routes implements IAppService,IAppServiceWithSharedComponent {


    getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
        const powerBIRoute: IStateConfig = {
        name: 'powerbi',
        url: '/powerbi',

generatedBundle: '62945cd87f5d-PowerBIModule-Bundle',
        data: {
                headerName: 'PowerBI Embedded',
                breadcrumbText: 'PowerBI Embedded',
                pageTitle: 'PowerBI Embedded'
             },
             
        };
        const routeInfo: IRouteInfo = {
            sharedBundles: [],
            routes: [powerBIRoute]
        }
    
        return routeInfo;
    }

    getSharedComponentsInfo(): ISharedComponents {
      const SharedPowerBIComponent : ISharedComponentConfig = {


generatedBundle: '62945cd87f5d-PowerBIModule-Bundle',
        componentDescription: 'Shared component to be utilized in Workbench for Engagement', 
        componentInputs:[['workbenchContext','']],
        //componentOutputs: [],
        componentName: 'power-bi',//selector name //share this//
        exposeAsExternalComponents: true,
        }

        return {

          appName: 'PowerBIEmbeddedApplication', // Name of the application. Use the same name what you have used in main.ts file with registerPartnerApp method.
          components: [SharedPowerBIComponent],          
          disableSharing: false,
          sharedBundles: [],        
           }

    }

    getServiceEndPoints(): Array<IServiceEndPoints> {

        return appSettings().serviceEndPoints;
    }

    getBundles(): IPartnerBundle[] {
        const baseUrl = appSettings().cdnBaseUrl;
        const bundle: IPartnerBundle = {
          name: 'SampleApp-Bundle', //TODO: Please use the format {appname}-{bundleName} to ensure the name of the bundle is always unique. 
          files: [
            `${baseUrl}/vendor.bundle.js`,
            `${baseUrl}/styles.bundle.js`,
            `${baseUrl}/main.bundle.js`,
          ],
          sequentialLoading: true,
        };

        return [bundle];
      }
  public getGeneratedBundles() {
    var baseUrl = '';
    var currentScriptUrl = document.currentScript['src'];
    if (currentScriptUrl.indexOf('exclude.inline.bundle') > 0) {
      currentScriptUrl = Error().stack.match(/(https?:\/\/[^ ]*)/g);
      baseUrl = currentScriptUrl
        .filter((item) => item.indexOf('localhost:5000') === -1)[0]
        .toLowerCase();
      baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    } else {
      baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/'));
    }
    if (baseUrl.endsWith("/")){
        baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    }
    return [
            {
                name: '62945cd87f5d-PowerBIModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/PowerBIModule.vendor.bundle.js',
                    baseUrl + '/PowerBIModule.bundle.js'
                ],
                sequentialLoading: true           
            },

    ]
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(PowerBIEmbeddedApplication_routes, 'PowerBIEmbeddedApplication');
