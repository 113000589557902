import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const prod: IPartnerAppSettings = {
  cdnBaseUrl: '',
  serviceEndPoints: [
    {
      clientId: 'e03cd7c9-8c94-45dd-b4b8-68957267ec20',
      serviceEndPoint: 'https://pslakeds.azurewebsites.net/eaapp/api/EA'
    },
    {

      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      serviceEndPoint: 'https://psdicentraapi.azurewebsites.net'

    }
  ],
};
